import {
  DatoCmsCertificationsBlock,
  DatoCmsMenuItemBlock,
  DatoCmsMightLike,
  DatoCmsNavigation,
  DatoCmsNeedHelp,
} from 'lib/datoCms/types';
import { DatoCmsHeader, DatoCmsImage, DatoCmsImageTheme, DatoCmsModalItem } from 'sites/happybet/lib/datoCms/types';
import { MenuItem, NavigationBlock } from 'components/footer/footerNavigation/FooterNavigation';
import { ReactNode, useEffect, useMemo } from 'react';
import { RootState, useAppDispatch, useTypedSelector } from 'sites/happybet/lib/store';
import { convertInImgType, convertInThemeLogoType } from 'sites/happybet/lib/datoCms/utils';

import { BackgroundImage } from 'components/backgroundImage';
import { CertificationBlock } from 'components/footer/footerDisclaimer/FooterDisclaimer';
import { Footer } from 'sites/happybet/components/footer';
import Head from 'next/head';
import { Header } from 'sites/happybet/components/header';
import { ImageProps } from 'next/image';
import { InstantPanelProps } from 'components/instantPanel/InstantPanel';
import { JumioModal } from 'sites/happybet/components/modals/jumioModal';
import { LoginModal } from 'sites/happybet/components/modals/loginModal';
import { LoginModalCms } from 'sites/happybet/lib/types/dato-cms.response';
import { MenuSticky } from '../menuSticky';
import { MightLikeContext } from 'context/Mightlikes';
import { ModalsWelcome } from 'sites/happybet/components/modalsWelcome';
import { NeedHelp } from 'components/needHelp';
import { SeamlessLoginComponent } from 'components/seamlessLoginComponent';
import { ThemeLogoTypeWithCustomData } from 'sites/happybet/utility/types';
import { breakpoint } from 'utility/constant';
import { isClientSide } from 'utility/functions';
import { selectStringAutoesclusion } from 'features/dashboard/selectors';
import { setIsOpenLoginModalValue } from 'features/modal/modalSlice';
import { setJumioModalAfterRegistrationOpen } from 'sites/happybet/features/slices/happybetSlice';
import styles from 'sites/happybet/components/Layout/Layout.module.scss';
import useAgentDetect from 'hooks/useAgentDetect';
import { useIsSeamlessMode } from 'hooks';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import useWindowSize from 'hooks/useWindowSize';
import { InactiveMessage } from 'components/wrapperInactiveLogout';

export type LayoutProps = {
  header?: DatoCmsHeader;
  footer?: any;
  needHelp?: DatoCmsNeedHelp;
  mightLikes?: DatoCmsMightLike[];
  children?: ReactNode;
  instantPanel?: InstantPanelProps;
  headerOnlyLogo?: boolean;
  modalItems?: DatoCmsModalItem[];
  newCardDigitalCustomerCardSkip: boolean;
  loginModal: LoginModalCms;
};

export const Layout = ({
  header,
  footer,
  needHelp,
  mightLikes,
  // instantPanel,
  children,
  headerOnlyLogo,
  newCardDigitalCustomerCardSkip,
  loginModal,
}: LayoutProps) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const seamlessMode = useIsSeamlessMode();
  const { isLoadingVendita } = useSelector((store: RootState) => store.carrello);
  const stringAutoesclusion = useTypedSelector(selectStringAutoesclusion);
  const { isSafari, isIos, isIPhone, isMac } = useAgentDetect();
  const { jumioModalAfterRegistrationOpen } = useSelector((store: RootState) => store.happybet);
  const { width } = useWindowSize();
  const isMobile = width! < breakpoint.md;

  const contentViewPort = useMemo(() => {
    if (isIPhone) return 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, viewport-fit=cover';
    return 'initial-scale=1.0, width=device-width, minimum-scale=1';
  }, [isIPhone]);

  let statusInit = '';
  if (isClientSide()) {
    statusInit = window.DigitalAssistant?.statusInit ?? '';
  }

  useEffect(() => {
    if (isClientSide()) {
      if (statusInit === 'initialized' && !router.asPath.includes('dashboard')) {
        window.DigitalAssistant.hide();
      }
    }
  }, [router.asPath, statusInit]);

  useEffect(() => {
    if (isClientSide()) {
      if (isIos && isSafari) {
        document.body.classList.add('bodyOs');
      } else if (!isMac) {
        document.body.classList.add('bodyNotOsDesktop');
      } else if (isMac) {
        document.body.classList.add('bodyOsDesktop');
      } else {
        document.body.classList.remove('bodyOs');
        document.body.classList.remove('bodyNotOsDesktop');
        document.body.classList.remove('bodyOsDesktop');
      }
    }
  }, [isIos, isSafari]);

  useEffect(() => {
    document.body.setAttribute('data-env', process.env.NEXT_PUBLIC_MODULE_SITE!);
  }, []);

  useEffect(() => {
    if (stringAutoesclusion) {
      dispatch(setIsOpenLoginModalValue(true));
    }
  }, [stringAutoesclusion, dispatch]);

  const { isOpenLoginModal } = useSelector((store: RootState) => store.modal);

  // const [lottery, setLottery] = useState({
  //   contrattoSisal: '',
  //   contrattoLottomatica: '',
  //   contrattoGrattaeVinci: '',
  // });

  let navigationRenamed, ippicaLogoListRenamed, paymentLogoListRenamed, certificationsBlockRenamed;
  if (footer) {
    navigationRenamed = footer.navigation.map((item: DatoCmsNavigation): NavigationBlock => {
      const menuList: MenuItem[] = item.menu[0].menuItemBlock.map((itemMenu: DatoCmsMenuItemBlock): MenuItem => {
        const { id, label, linkBlock: links } = itemMenu;
        const menuItem: MenuItem = { id, label, links };
        return menuItem;
      });
      return {
        id: item.id,
        title: item.title,
        menuList: menuList,
      };
    });

    ippicaLogoListRenamed = footer.ippicaLogoList.map(
      (item: DatoCmsImage): ImageProps & { customData?: { [key: string]: string } } => {
        return convertInImgType(item);
      }
    );
    paymentLogoListRenamed = footer.paymentLogoList.map(
      (paymentLogo: DatoCmsImageTheme): ThemeLogoTypeWithCustomData => {
        return convertInThemeLogoType(paymentLogo);
      }
    );

    certificationsBlockRenamed = footer.certificationsBlock.map(
      (item: DatoCmsCertificationsBlock): CertificationBlock => {
        const logos: DatoCmsImageTheme[] = item.logo;
        const logoThemeList = logos.map(
          (logo: DatoCmsImageTheme): ThemeLogoTypeWithCustomData => convertInThemeLogoType(logo)
        );
        return {
          id: item.id,
          title: item.title,
          logoList: logoThemeList,
        };
      }
    );
  }

  return (
    <MightLikeContext.Provider value={mightLikes!}>
      <div className={isLoadingVendita ? styles.isLoadingVendita : ''}>
        <Head>
          {/* <title>Snai</title> */}
          <meta name="viewport" content={contentViewPort} />
        </Head>
        {header && !seamlessMode && (
          <Header
            logoSnaiPi={header.logoSnaiPi[0]}
            logoBrand={convertInThemeLogoType(header.logoBrand[0])}
            navigationList={header.navigationList}
            promoHref={header.promoHref}
            profileHref={header.profileHref}
            snaiMessageHref={header.snaiMessageHref}
            userNavigationList={header.userNavigationList}
            mobileUserNavigationList={header.mobileUserNavigationList}
            onlyLogo={headerOnlyLogo}
          />
        )}
        <InactiveMessage />
        <SeamlessLoginComponent />
        <ModalsWelcome newCardDigitalCustomerCardSkip={newCardDigitalCustomerCardSkip} />
        {/* TODO capire se vogliamo vada oltre al colonnaggio o no */}
        <BackgroundImage
          src="https://www.datocms-assets.com/108476/1699364983-background-happybet.svg"
          containerClassName={styles.containerLogo}
          imageClassName={styles.logo}
        />

        <main className={styles.container}>{children}</main>
        {needHelp && !seamlessMode && (
          <div className={styles.container}>
            <NeedHelp heading={needHelp.title} optionList={needHelp.option} />
          </div>
        )}
        {footer && !seamlessMode && (
          <Footer
            settingsTitle={footer.settingsTitle}
            settingsLabelTema={footer.settingsLabelTema}
            switchTheme={footer.switchTheme[0]}
            ippicaTitle={footer.ippicaTitle}
            ippicaLogoList={ippicaLogoListRenamed}
            paymentLogoList={paymentLogoListRenamed}
            footerNavigation={navigationRenamed}
            disclaimerParagraph={footer.disclaimer}
            warningLogo={footer.logoAdm18}
            warningText={footer.avvisoAdm}
            certificationsBlock={certificationsBlockRenamed}
            selectLingua={footer.selectLingua}
          />
        )}

        {isMobile && header && <MenuSticky navigationList={header.navigationList} />}
      </div>
      {header && (
        <LoginModal
          loginModal={loginModal}
          logoBrand={convertInThemeLogoType(header.logoBrand[0])}
          isOpen={isOpenLoginModal}
          handleClose={() => dispatch(setIsOpenLoginModalValue(false))}
        />
      )}
      {/* <SnaiPlusFrame /> */}
      {jumioModalAfterRegistrationOpen && (
        <JumioModal
          isOpen={jumioModalAfterRegistrationOpen}
          handleClose={() => dispatch(setJumioModalAfterRegistrationOpen(false))}
        />
      )}
    </MightLikeContext.Provider>
  );
};
