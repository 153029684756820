import styles from './LoginModalBody.module.scss';

import { resetStateDashboard, setAutoEsclusionString, setWalletData } from 'features/dashboard/dashboardSlice';
import { signIn, useSession } from 'next-auth/react';
import { Dispatch, SetStateAction, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import {
  dialogOnlyDeInfoChange,
  resetStateDialogDe,
  setModalsToOpen,
} from 'sites/happybet/features/dialogDe/dialogDeSlice';
import { setIsCustomerCard, setUserActivity } from 'sites/happybet/features/slices/happybetSlice';
import { useAppDispatch, useTypedSelector } from 'sites/happybet/lib/store';
import { FIRTS_LOGIN_COOKIE, IS_LOGGED, PROVENIENCE_QUERY_PARAM, isAustrianSite, isGermanSite } from 'utility/constant';
import { detectMob, isTruthy } from 'utility/functions';
import { PathName, getRouteByKey } from 'utility/routes';

import { AuthError } from 'types/errors';
import { AuthErrorType } from 'types/login';
import { BackgroundImage } from 'components/backgroundImage';
import { BannerInformation } from 'components/bannerInformation';
import { Button } from 'components/button/Button';
import { PasswordInput } from 'components/inputs/passwordInput';
import { TextInput } from 'components/inputs/textInput';
import { apiSlice } from 'features/api/apiSlice';
import { useLazyGetCachedProfileQuery } from 'features/api/userSlice';
import { selectStringAutoesclusion } from 'features/dashboard/selectors';
import { getCurrentLocale, getLngFromCultureName, useGetLabelByKey } from 'hooks/useLingUI';
import { stringNotEmpty } from 'lib/labels';
import { setCookie } from 'lib/persist/cookie';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { apiMasterTokenHappybet } from 'sites/happybet/features/api/apiMasterTokenHappybet';
import { z } from 'zod';
import { IOVATION_URL } from 'lib/policy/types/happybet';
import { getNonce } from 'lib/policy';
import { zodResolver } from '@hookform/resolvers/zod';
import Persist, { StorageKind } from 'lib/persist';
import { mutex } from 'components/refreshTokenEngine';
import { IconsEnum } from 'components/Icons';
import { useGetModalByKey } from 'sites/happybet/hooks/useGetModalByKey';
import { setIsOpenLoginModalValue } from 'features/modal/modalSlice';
import { PostLoginModalType } from 'sites/happybet/components/modalsWelcome/ModalsWelcome';

export type LoginModalBodyProps = {
  title?: string;
  buttonLabel: string;
  placeholderPassword: string;
  placeholderPin: string;
  placeholderUsername: string;
  placeholderCard: string;
  labelUsername: string;
  labelPassword: string;
  forgotPasswordLabel: string;
  labelLoginUsernamePassword: string;
  labelLoginCutomerCard: string;
  labelCarta: string;
  labelPin: string;
  labelAccedi: string;
  blockedAccountLoginError: string;
  usernamePasswordError: string;
  registerLoginModalMessage: string;
  registerLabel: string;
  autoEsclusionMoreInfoUrl: string;
  redirectOnSuccess?: string;
  handleClose: Dispatch<SetStateAction<boolean>>;
};

// declare global {
//   interface Window {
//     gtag: any;
//     fbq: any;
//     uetq: any;
//     snaptr: any;
//     srtmCommands: any;
//     dataLayer: any;
//   }
// }

export const LoginModalBody = ({
  title,
  buttonLabel,
  redirectOnSuccess,
  placeholderPassword,
  // placeholderPin,
  placeholderUsername,
  // placeholderCard,
  labelUsername,
  labelPassword,
  forgotPasswordLabel,
  // labelLoginUsernamePassword,
  // labelLoginCutomerCard,
  // labelCarta,
  // labelPin,
  // labelAccedi,
  blockedAccountLoginError,
  usernamePasswordError,
  registerLoginModalMessage,
  registerLabel,
  autoEsclusionMoreInfoUrl,
  handleClose,
}: // ...rest
LoginModalBodyProps) => {
  const dispatch = useAppDispatch();
  const { asPath, push } = useRouter();

  const { data: session, status } = useSession();

  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState<string>();
  const [isNewLogin, setNewLogin] = useState(false);
  const [urlRedirect, setUrlRedirect] = useState<string>();
  const [urlLabelButton, setUrlLabelButton] = useState<string>();

  const stringAutoesclusion = useTypedSelector(selectStringAutoesclusion);
  const registrationUrl = useMemo(() => getRouteByKey(PathName.registrationUrl), []);

  const labelMaggioriInfo = useGetLabelByKey('maggiori-info');
  const uniqueAccountModal = useGetModalByKey('unique-account-modal');

  const [getSessionCacheProfile] = useLazyGetCachedProfileQuery();

  const slugProvenienza = useMemo(() => {
    const splittedSlug = asPath.split('/');
    const slug = splittedSlug.length > 1 ? splittedSlug[1] : splittedSlug[0];
    return slug || 'homepage';
  }, [asPath]);

  const schema = z.object({
    username: stringNotEmpty,
    password: stringNotEmpty,
  });
  type ValidationSchema = z.infer<typeof schema>;
  const { control, handleSubmit } = useForm<ValidationSchema>({
    resolver: zodResolver(schema),
  });

  const psw = useWatch({ control, name: 'password' });

  const isLoginWihtCard = useMemo(() => {
    const regex = /^\d{4}$/;
    return regex.test(psw);
  }, [psw]);

  const getSessionCacheProfileCallback = useCallback(async () => {
    let isWelcomeMessageUniqueaccount = false;
    let isWelcomeMessageModal = false;
    let isRefillModalOpen = false;
    let isLimitsModalOpen = false;
    let isJumioModalOpen = false;
    let message: string | undefined;

    const arrayToOpen: PostLoginModalType[] = [];

    if (session) {
      const { user } = session ?? {};
      const { numVersamento, userActivity } = user ?? {};
      const { data } = await getSessionCacheProfile();

      dispatch(
        setWalletData({
          saldo: Number(data?.saldo_carta ?? 0),
          bonusGold: Number(data?.saldo_wagering ?? 0),
        })
      );
      isWelcomeMessageModal = isTruthy(data?.welcome_message_flg);
      message = data?.welcome_message_msg;
      isWelcomeMessageUniqueaccount = isTruthy(data?.welcome_message_uniqueaccount);
      isLimitsModalOpen = isTruthy(data?.welcome_message_limits);
      isRefillModalOpen = !numVersamento || numVersamento === '0';
      isJumioModalOpen = isTruthy(data?.welcome_message_jumio);

      if (isGermanSite) {
        if (userActivity) {
          dispatch(setUserActivity(userActivity));
        }
        if (isGermanSite) {
          arrayToOpen.push({ type: 'balance-last-30-days' });
        }
      }
    }

    if (isWelcomeMessageModal) {
      if (isRefillModalOpen) {
        arrayToOpen.push({ type: 'first-refill' });
      }
      if (isLimitsModalOpen) {
        arrayToOpen.push({ type: 'limits', message });
      }
      if (isWelcomeMessageUniqueaccount) {
        arrayToOpen.push({ type: 'create-digital-card', message });
      }
      if (isJumioModalOpen) {
        arrayToOpen.push({ type: 'welcome-jumio-modal', message });
      }
      if (!isRefillModalOpen && !isLimitsModalOpen && !isWelcomeMessageUniqueaccount && !isJumioModalOpen) {
        arrayToOpen.push({ type: 'welcome-message', message });
      }
    }

    dispatch(setModalsToOpen(arrayToOpen));
  }, [dispatch, getSessionCacheProfile, session]);

  useLayoutEffect(() => {
    if (isNewLogin) {
      if (status === 'authenticated') {
        if (redirectOnSuccess) {
          push(redirectOnSuccess);
        } else {
          handleClose(true);
        }
      }
    }
  }, [redirectOnSuccess, push, status, isNewLogin, handleClose]);

  useEffect(() => {
    if (isNewLogin && session) {
      getSessionCacheProfileCallback();
    }
  }, [session, isNewLogin, getSessionCacheProfileCallback]);

  useLayoutEffect(() => {
    if (isNewLogin && session) {
      //sportradar
      const anyW = window as any;
      anyW.srtmCommands = anyW.srtmCommands || [];
      anyW.srtmCommands.push({
        event: 'track.user.login',
        payload: {
          action: 'complete',
          userId: session?.user?.cardNumber, //'<carta>',
        },
      });

      const firstLogin = document.cookie.includes(FIRTS_LOGIN_COOKIE);
      if (!firstLogin) {
        setCookie(FIRTS_LOGIN_COOKIE, new Date().getTime().toString(), 30);
      }
      dispatch(setIsCustomerCard(session?.user.isCustomerCard));
    }
  }, [session, dispatch, isNewLogin, getSessionCacheProfileCallback]);

  const submit: SubmitHandler<ValidationSchema> = async ({ password, username }) => {
    setIsLoading(true);

    dispatch(apiSlice.util.resetApiState());
    dispatch(apiMasterTokenHappybet.util.resetApiState());
    if (stringAutoesclusion) dispatch(setAutoEsclusionString(undefined));
    dispatch(resetStateDialogDe());
    dispatch(resetStateDashboard());

    let iovationCode!: string;
    const iovationEnabled = isTruthy(`${process.env.NEXT_PUBLIC_IOVATION_CODE_ENABLED}`);

    // @ts-ignore
    if (iovationEnabled && window?.IGLOO?.getBlackbox) {
      // @ts-ignore
      iovationCode = window.IGLOO.getBlackbox().blackbox;
    }

    const validateEmailRegex = /^\S+@\S+\.\S+$/;

    const isEmail = username.match(validateEmailRegex);
    const locale = getCurrentLocale();

    try {
      // lock other api calls during the login intervall
      await mutex.acquire();
    } catch (ex) {
      console.log('mutex-exc', ex);
    }
    const response = await signIn('credentials', {
      redirect: false,
      username,
      password,
      iovationCode,
      frontendType: detectMob() ? '60' : '1',
      lang: getLngFromCultureName(locale),
      accessMode: isEmail && !isLoginWihtCard ? '3' : isLoginWihtCard ? '1' : '2',
    });

    Persist(StorageKind.cookie).setItem(IS_LOGGED, `${response?.ok}`);

    const isSuccess = isTruthy(response?.ok);

    if (isSuccess) {
      let { fbq, dataLayer } = window as any;
      //Google Tag Manager (GTM is on window.dataLayer and not in window.gtag)
      dataLayer.push('event', 'success', {
        event_category: 'login',
        event_label: '123456',
      });

      //Facebook Pixel
      if (typeof fbq === 'function') fbq('trackCustom', 'Login');
      //ON RENDER AS <Script
      //emx https://secure.adnxs.com/px?id=1475290&t=1
    } else {
      const { data } = JSON.parse(response?.error!) as AuthError;
      setErrorText(
        data.message === AuthErrorType.InvalidGrant
          ? usernamePasswordError
          : data.message === AuthErrorType.BlockedAccount
          ? blockedAccountLoginError
          : data.message
      );
      data.urlRedirect && setUrlRedirect(data.urlRedirect);
      data.urlLabelButton && setUrlLabelButton(data.urlLabelButton);
      if (data.uniqueAccountModal) {
        dispatch(setIsOpenLoginModalValue(false));
        if (data.contractCode) Persist(StorageKind.local).setItem('contract-code-new-digital-card', data.contractCode);
        dispatch(
          dialogOnlyDeInfoChange({
            isOpen: true,
            icon: IconsEnum.INFO,
            title: uniqueAccountModal?.title,
            subtitle: data.message,
            closeAction: () => {
              dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
            },
            type: 'login-force-new-card',
            buttons: [
              <Button
                key={0}
                visualizationType={'primary'}
                onClick={() => {
                  dispatch(dialogOnlyDeInfoChange({ isOpen: false }));
                  push('/register/new-digital-card');
                }}
              >
                {uniqueAccountModal?.buttonsBlock[1].label}
              </Button>,
            ],
          })
        );
      }
    }

    if (isSuccess) {
      // await an arbitrary delay to allow new session data to be loaded
      await new Promise((resolve) => setTimeout(resolve, 499));
    }
    try {
      // unlock other api calls during the login intervall
      await mutex.release();
    } catch (ex) {
      console.log('mutex-exc', ex);
    }

    setIsLoading(false);
    setNewLogin(isSuccess);
  };

  return (
    <>
      {/*EMX*/}
      <Script src="https://secure.adnxs.com/px?id=1475290&t=1" id="emx" strategy="lazyOnload" />

      <Script src={IOVATION_URL} nonce={getNonce()} />
      {errorText && !urlRedirect && !urlLabelButton ? (
        <BannerInformation text={errorText!} type="error" visible={!!errorText} layoutType="big" />
      ) : (
        <BannerInformation
          text={errorText!}
          type="error"
          visible={!!errorText}
          layoutType="big"
          redirectButton={{
            element: urlLabelButton!,
            url: urlRedirect!,
          }}
        />
      )}
      {stringAutoesclusion && !errorText && (
        <BannerInformation
          text={stringAutoesclusion!}
          type="success"
          visible={!!stringAutoesclusion}
          redirectButton={{
            element: <span>{labelMaggioriInfo}</span>,
            url: autoEsclusionMoreInfoUrl,
          }}
        />
      )}
      <div className={styles.container}>
        <BackgroundImage src="https://www.datocms-assets.com/108476/1699364983-background-happybet.svg" />
        <form className={styles.form} onSubmit={handleSubmit(submit)}>
          {title && <span className={styles.title}>{title}</span>}
          <TextInput
            name="username"
            control={control}
            label={labelUsername}
            autoComplete="username"
            placeholder={placeholderUsername}
            hasMarginBottom
          />
          <PasswordInput
            autoComplete="current-password"
            label={labelPassword}
            placeholder={placeholderPassword}
            control={control}
            name={'password'}
          />
          <a href={'/user/password'} className={styles.link}>
            {forgotPasswordLabel}
          </a>
          <Button type="submit" className={styles.button} isLoading={isLoading}>
            {buttonLabel}
          </Button>
        </form>
        {!isAustrianSite && (
          <div className={styles.boxesContainer}>
            <article className={styles.box}>
              <p className={styles.textsContainer}>
                <strong className={styles.boxTitle}>{registerLoginModalMessage}</strong>
              </p>
              <button
                className={styles.boxBtn}
                onClick={() => push(`${registrationUrl}?${PROVENIENCE_QUERY_PARAM}=newhpybet_${slugProvenienza}`)}
              >
                {registerLabel}
              </button>
            </article>
          </div>
        )}
      </div>
    </>
  );
};
