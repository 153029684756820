import { AnchorLink, AnchorLinkType } from 'components/anchorLink';
import { Icon, IconsEnum } from 'components/Icons';
import { MenuItemBlock, Navigation } from '../Header';
import React, { useCallback, useMemo } from 'react';

import { DetailsMenu } from 'components/header/detailsMenu';
import { HeaderMenuItem } from 'components/header/headerMenuItem';
import classNames from 'classnames';
import { selectIsCustomerCard } from 'sites/happybet/features/slices/selectors';
import styles from './HeaderNavigation.module.scss';
import { useInternalSession } from 'hooks/useInternalSession';
import { useRouter } from 'next/router';
import { useTypedSelector } from 'sites/happybet/lib/store';

export type HeaderNavigationProps = {
  id: number | string | null;
  navigation: (Navigation | AnchorLinkType)[];
  allNavigationList: (Navigation | AnchorLinkType)[];
  showDetail: boolean;
  indexItem: number;
  handleShowDetailsMenu: Function;
};

const objIcon = <Icon iconId={IconsEnum.CHEWRON_DOWN} color="var(--color-primary)" />;

export const HeaderNavigation: React.FC<HeaderNavigationProps> = ({
  navigation,
  allNavigationList,
  id,
  indexItem,
  showDetail,
  handleShowDetailsMenu,
}: HeaderNavigationProps) => {
  const idSport = '64023360';
  const idSlots = 'RxVT5vFVR7-RTWzx4e_dHg';

  const sportChild = ['live', 'ippica'];
  const isCustomerCard = useTypedSelector(selectIsCustomerCard);

  const router = useRouter();
  const { session } = useInternalSession();
  const isActiveElement = useCallback((item: MenuItemBlock[] | AnchorLinkType, route: string) => {
    if (Array.isArray(item)) {
      const currentRoute = item.some(({ linkBlock, id }: MenuItemBlock) => {
        return String(linkBlock[0].href).replace('/', '') === route.slice(1, String(linkBlock[0].href).length);
      });
      return currentRoute;
    }
    return (
      (idSport === item.id && sportChild.includes(route.slice(1, String(item.href).length).replace('/', ''))) ||
      String(item.href).replace('/', '') === route.slice(1, String(item.href).length)
    );
  }, []);

  const itemClasses = useMemo(() => {
    return {
      item: styles.item,
      itemActive: styles.itemActive,
      itemDisable: styles.itemDisable,
      itemFadeLine: styles.itemFadeLine,
      itemRouterActive: styles.itemRouterIsActive,
      itemRouteDisable: styles.itemRouterIsDisable,
      itemIsInHome: styles.itemIsInHome,
    };
  }, []);

  return (
    <nav className={styles.navigation}>
      <ul className={styles.list}>
        {navigation &&
          navigation.map((singleItem: Navigation, index) => {
            const { menuItemBlock } = singleItem.navigationItemBlock![0];
            if (menuItemBlock) {
              const isOpenDropdown = +indexItem === +singleItem.id && showDetail;
              const className = classNames(styles.arrow, isOpenDropdown ? styles.arrowUp : styles.arrowDown);
              const itemIcon = { ...objIcon, props: { ...objIcon.props, className } };

              return (
                <HeaderMenuItem
                  id={id}
                  key={+singleItem.id}
                  currentId={singleItem.id}
                  itemClasses={itemClasses}
                  isOpenDropdown={isOpenDropdown}
                  isActiveElement={isActiveElement(menuItemBlock, router.pathname)}
                >
                  <button
                    type="button"
                    className={styles.buttonItem}
                    aria-expanded={isOpenDropdown ? 'true' : 'false'}
                    onClick={() => handleShowDetailsMenu(singleItem.id, allNavigationList)}
                  >
                    {singleItem.label}
                    {itemIcon}
                  </button>
                  <DetailsMenu
                    index={index}
                    showDetail={isOpenDropdown}
                    menuItemBlock={menuItemBlock}
                    currentIndex={+indexItem}
                  ></DetailsMenu>
                </HeaderMenuItem>
              );
            }
            return isCustomerCard && (singleItem.id === idSlots || singleItem.label === 'Slots') ? null : (
              <HeaderMenuItem
                key={singleItem.id}
                itemClasses={itemClasses}
                id={id}
                currentId={singleItem.id}
                isActiveElement={isActiveElement(singleItem.navigationItemBlock![0], router.pathname)}
              >
                {singleItem.id === idSlots || singleItem.label === 'Slots' ? (
                  <AnchorLink
                    href={
                      session
                        ? `${process.env.NEXT_PUBLIC_SLOTS_AS_IS_BASE_URL}/seamless/access/${session?.user.token}/${session?.user.cardNumber}/nsport/1/`
                        : `${process.env.NEXT_PUBLIC_SLOTS_AS_IS_BASE_URL}`
                    }
                    className={styles.anchorlink}
                    target="_self"
                  >
                    {singleItem.label}
                  </AnchorLink>
                ) : (
                  <AnchorLink href={`${singleItem.navigationItemBlock![0].href}`} className={styles.anchorlink}>
                    {singleItem.label}
                  </AnchorLink>
                )}
              </HeaderMenuItem>
            );
          })}
      </ul>
    </nav>
  );
};
